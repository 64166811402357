import { HorizontalBar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
	extends: HorizontalBar,
	mixins: [reactiveProp],
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
	},
	mounted() {
		// this.chartData is created in the mixin.
		// If you want to pass options please create a local options object
		this.renderChart(this.chartData)
	},
}
