<template>
	<v-row class="mx-0 pa-4">
		<v-col cols="12" class="pa-0">
			<v-card>
				<h1 class="section-heading pa-4">
					How customers search for your business
				</h1>
				<v-row
					class="d-flex flex-row justify-space-between align-start pa-4 mx-0"
				>
					<v-col cols="5" class="pa-0 pl-3">
						<Doughnut :chart-data="searchesData"></Doughnut>
					</v-col>
					<v-col cols="7" class="pa-0">
						<ul>
							<li class="mb-3">
								<h4>Direct</h4>
								<p class="body-1">
									People who find your Business Profile
									searching for your business name or address
								</p>
							</li>
							<li class="mb-3">
								<h4>Discovery</h4>
								<p class="body-1">
									People who find your Business Profile
									searching for a category, product, or
									service
								</p>
							</li>
							<li>
								<h4>Branded</h4>
								<p class="body-1">
									Customers who find your listing searching
									for a brand related to your business
								</p>
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-card>
			<v-card class="pa-4">
				<h1 class="section-heading">
					Where customers view your business on Google
				</h1>
				<p>
					The Google services that customers use to find your business
				</p>
				<v-row
					class="d-flex flex-row justify-space-between align-center mx-0 mt-8"
				>
					<v-col cols="7" class="pa-0 pl-3">
						<LineChart :chart-data="viewsData"></LineChart>
					</v-col>
					<v-col cols="5" class="pa-0">
						<ul>
							<li class="mb-3">
								<h4>Listing on Search</h4>
								<p class="body-1">498</p>
							</li>
							<li class="mb-3">
								<h4>Listing on Maps</h4>
								<p class="body-1">283</p>
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-card>
			<v-card class="pa-4">
				<h1 class="section-heading">Customer actions</h1>
				<p>
					The most common actions that customers take on your listing
				</p>
				<v-row
					class="d-flex flex-row justify-space-between align-center mx-0 mt-8"
				>
					<v-col cols="7" class="pa-0 pl-3">
						<Bar :chart-data="actionsData"></Bar>
					</v-col>
					<v-col cols="5" class="pa-0">
						<ul>
							<li class="mb-3">
								<h4>Visits Your Website</h4>
								<p class="body-1">32</p>
							</li>
							<li class="mb-3">
								<h4>Request Directions</h4>
								<p class="body-1">6</p>
							</li>
							<li class="mb-3">
								<h4>Call you</h4>
								<p class="body-1">6</p>
							</li>
						</ul>
					</v-col>
				</v-row>
			</v-card>
			<v-card class="pa-4">
				<h1 class="section-heading">Photo quantity</h1>
				<p>
					The number of photos that appear on your business, compared
					to photos from other businesses
				</p>
				<v-row
					class="d-flex flex-row justify-center align-center mx-0 mt-8"
				>
					<v-col cols="12" class="pa-0 pl-3">
						<HorizontalBar :chart-data="photosData"></HorizontalBar>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import Doughnut from '@/components/Charts/Doughnut.js'
import LineChart from '@/components/Charts/LineChart.js'
import Bar from '@/components/Charts/Bar.js'
import HorizontalBar from '@/components/Charts/HorizontalBar.js'

export default {
	components: {
		Doughnut,
		LineChart,
		Bar,
		HorizontalBar,
	},
	data() {
		return {
			searchesData: {},
			viewsData: {},
			actionsData: {},
			photosData: {},
		}
	},
	computed: {
		selectedOrg: {
			get() {
				return this.$store.getters['gmb/getSelectedOrg']
			},
		},
	},
	created() {
		if (this.selectedOrg) {
			this.getInsights()
		} else {
			this.$router.push({ name: 'gmb.orgs' })
		}
	},
	methods: {
		getInsights() {
			let payload = {
				name: this.selectedOrg.name,
				startTime: '2021-01-01T15:19:05.779Z',
				endTime: '2021-01-21T15:19:05.779Z',
				interval: 'monthly',
				email: this.$store.state.gmb.selectedAccountEmail,
			}
			const projectId = this.$store.state.app.projectId
			return this.axios({
				method: 'post',
				url: `/${projectId}/gmb/org/selected/insights`,
				data: payload,
			}).then((response) => {
				console.log(response.data)
				if (
					response.data.insights.locationMetrics &&
					response.data.insights.locationMetrics.length > 0
				) {
					let data =
						response.data.insights.locationMetrics[0].metricValues
					this.searchesData = {
						labels: ['Direct', 'Discovery'],
						datasets: [
							{
								backgroundColor: ['#1B5E', '#ABEBC6 '],
								data: [
									data[0].totalValue.value,
									data[1].totalValue.value,
								],
							},
						],
					}
					this.viewsData = {
						labels: ['January', 'February'],
						datasets: [
							{
								label: 'Maps',
								backgroundColor: '#F7DC6F ',
								data: [0, data[3].totalValue.value],
							},
							{
								label: 'Search',
								backgroundColor: '#F9E79F ',
								data: [0, data[4].totalValue.value],
							},
						],
					}
					this.actionsData = {
						labels: ['January', 'February'],
						datasets: [
							{
								label: 'Visit Your Website',
								backgroundColor: '#2980B9 ',
								data: [40, 25],
							},
							{
								label: 'Request Directions',
								backgroundColor: '#A9CCE3 ',
								data: [5, 6],
							},
							{
								label: 'Call you',
								backgroundColor: '#D2B4DE  ',
								data: [0, 0],
							},
						],
					}
					this.photosData = {
						labels: ['January', 'February'],
						datasets: [
							{
								label: 'Customer Photos',
								backgroundColor: '#73C6B6',
								data: [45, 60],
							},
							{
								label: 'Owner Photos',
								backgroundColor: '#ABEBC6 ',
								data: [50, 50],
							},
						],
					}
				}
			})
		},
	},
}
</script>

<style>
.section-heading {
	font-size: 1.5rem !important;
	font-weight: 400 !important;
}
.mdi-thumb-up-outline {
	font-size: 23px;
	color: blue;
}
.mdi-thumb-down-outline {
	font-size: 23px;
	color: grey;
}
#doughnut-chart {
	width: 250px !important;
	height: 250px !important;
}
canvas {
	height: 400px !important;
	width: 400px !important;
}
</style>
